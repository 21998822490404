import React, { memo } from "react"
import styled from "../context/theme"

export const Backdrop = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1003;
`

const StyledModal = styled.div`
  max-width: 420px;
  width: 100%;
  padding: 28px 25px 28px;
  border-radius: 8px;
  text-align: center;
`

export const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const Modal = memo(({ children, ...props }) => {
  return <StyledModal {...props}>{children}</StyledModal>
})
