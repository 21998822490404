import { css } from "@emotion/core"
import React, { memo } from "react"
import { useNotificationStore } from "../context/stores"
import styled from "../context/theme"

export const Backdrop = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2003;
`

const StyledNotification = styled.div`
  text-align: center;
  background-color: white;
  color: #222026;
  padding: 5px;
  border: 1px solid #a8a4ac;
`

const NotificationWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props: { show: boolean }) =>
    props.show
      ? css`
          top: 0px;
          transition: top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        `
      : css`
          top: -50px;
          transition: top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        `}
`

export const Notification = memo(({ children, ...props }) => {
  const { state } = useNotificationStore()
  return (
    <NotificationWrapper show={state.show}>
      <StyledNotification {...props}>{state.text}</StyledNotification>
    </NotificationWrapper>
  )
})
