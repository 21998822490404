import styled from "../context/theme"

const Self = styled.div`
  background-image: url("images/logo-purple.png");
  background-size: cover;
  width: 133px;
  height: 50px;
  margin-top: 30px;
  label: Logo;
`

export default ({ ...props }) => <Self {...props} />
