import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import TagManager from "react-gtm-module"
import styled from "@emotion/styled"

import Footer from "./footer"
import { Backdrop } from "./modals"
import { Backdrop as NotificationBackdrop, Notification } from "./notifications"
import { useModalStore } from "../context/stores"

const Main = styled.main`
  position: relative;
`

const Layout = ({ children }) => {
  const { state: modalState } = useModalStore()

  useEffect(() => {
    TagManager.initialize({
      gtmId: "GTM-M4GZV3F",
    })
  }, [])

  return (
    <>
      <NotificationBackdrop>
        <Notification />
      </NotificationBackdrop>

      <Helmet />
      <Main>{children}</Main>
      <Footer />

      {modalState.modals.length > 0 &&
        modalState.modals.map(modal => <Backdrop>{modal}</Backdrop>)}
    </>
  )
}

export default Layout
