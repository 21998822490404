import React, { memo } from "react"

import LogoOrig from "./logo"
import styled from "../context/theme"

const Self = styled.footer`
  background-color: #e8e6eb;
  color: #4a494d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  label: Footer;
`

const EmailLink = styled.a`
  color: #4a494d;
  text-decoration: none;
`

const FooterText = styled.div`
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeight.medium};
  line-height: 22px;
  margin-top: 10px;
  text-align: center;
`

const HomepageLink = styled.a`
  color: #4a494d;
  text-decoration: none;
`

const Logo = styled(LogoOrig)`
  margin-top: 50px;
`

const PrivacyLink = styled.a`
  color: #4a494d;
  text-decoration: none;
`

const SnsButton = styled.a<{
  image: string
}>`
  width: 27px;
  height: 27px;
  margin: 0 10px;
  background-image: url("${props => `images/${props.image}`}");
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
  text-decoration: none;
`

const SnsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export default memo(() => {
  return (
    <Self className="ga-scroll" data-ga-section="Footer">
      <SnsRow className="ga-social">
        <SnsButton
          href={`https://youtube.com/channel/UCJScFhgZV254qGkEOCJVB9Q`}
          image="icn-youtube.inline.svg"
          tabIndex={1}
          target="_blank"
          title="유투브"
        ></SnsButton>
        <SnsButton
          href={`https://instagram.com/dotface_official`}
          image="icn-instagram.inline.svg"
          tabIndex={2}
          target="_blank"
          title="인스타그램"
        ></SnsButton>
        <SnsButton
          href={`https://facebook.com/facespeakawake`}
          image="icn-facebook.inline.svg"
          tabIndex={3}
          target="_blank"
          title="페이스북"
        ></SnsButton>
        <SnsButton
          href={`https://twitter.com/DotFace`}
          image="icn-twitter.inline.svg"
          tabIndex={4}
          target="_blank"
          title="트위터"
        ></SnsButton>
      </SnsRow>

      <Logo />

      <FooterText>
        (주)닷페이스
        <br />
        <HomepageLink
          href="https://dotface.kr"
          target="_blank"
          title="닷페이스 홈페이지"
        >
          dotface.kr
        </HomepageLink>
        <br />
        대표: 조소담 | 사업자번호: 7068600591
        <br />
        서울특별시 중구 청계천로 40, 1201호
        <br />
        문의:{" "}
        <EmailLink
          href="mailto:we@dotface.kr"
          target="_blank"
          title="닷페이스 공식이메일"
        >
          we@dotface.kr
        </EmailLink>{" "}
        |{" "}
        <PrivacyLink
          href="https://www.notion.so/dotface/de70611e21444643aca1a305050330ff"
          target="_blank"
          title="개인정보 취급방침"
        >
          개인정보취급방침
        </PrivacyLink>
        <br />© 2020. Dotface All Rights Reserved.
      </FooterText>
    </Self>
  )
})
